import { makeStyles } from "@material-ui/core/styles";
import { relative } from "path";

const Css = makeStyles((theme) => ({
  /*Home Page*/
  "a:hover": {
    color: "red",
  },
  item: {
    "&.hide-hover": {
      "& $crossmark": {
        opacity: "0!important",
      },
    },
    "&:hover": {
      "&.hide-hover": {
        "& $crossmark": {
          opacity: "0!important",
        },
      },
    },
    "&:hover": {
      zIndex: 1000,

      textDecoration: "none",
      "& $crossmark": {
        opacity: 1,
      },
    },

    "&:hover ~ .item__asset": {
      opacity: 0.5, // Réduit l'opacité des autres éléments suivants
    },
  },
  cover: {
    opacity: 1,
    // filter: "blur(4px)",
    transition: "all 0.5s ease",
    "&:hover": {
      opacity: 1,
      transition: "all 0.5s ease",
      filter: "blur(0px)",
    },
  },
  coverScale: {
    transition: "all 0.5s",
    "&.hide-hover:hover": {
      transform: "scale(1)",
    },
    "&:hover": {
      transform: "scale(1.1)",
      zIndex: 1000,
    },
  },
  positionImage: {
    position: "absolute",
    margin: "auto",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    maxWidth: "100%",
    maxHeight: "100%",
    visibility: "hidden",
  },
  video: {
    // position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    // backgroundColor: "black",
    borderRadius: "0.2rem",
    "& video": {
      // borderRadius: "10px",
    },
  },

  /*Video in the Modal*/
  videoWrapper: {
    position: "relative",
    pointerEvents: "auto",
    paddingBottom: "56.25%",
    paddingTop: theme.spacing(3.1),
    height: 0,
    // borderRadius: "10px",
    minWidth: "65vw",
    "@media (max-width: 600px)": {
      width: "100vw",
    },
    "& iframe": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      // borderRadius: "10px",
    },
  },
  reactPlayer: {
    // borderRadius: "10px",
  },
  itemFx: {
    position: "fixed",
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  title: {
    pointerEvents: "none",
    fontWeight: "bold",
    fontSize: "1rem",
    lineHeight: 1.2,
    fontFamily: "Helvetica Neue",
    textTransform: "uppercase",
    color: "#fff",
    textAlign: "left",
  },
  imageTitle: {
    background: "none",
    pointerEvents: "none",
    "&:hover": {
      color: "red",
    },
  },
  assetrelative: {
    position: "relative",
  },
  crossmark_block: {
    width: "100%",
    height: "100%",
    padding: "1rem",
    position: "absolute",
    left: 0,
    top: 0,
    color: "#fff",
    fontWeight: "bold",

    backgroundPosition: "center",

    fontSize: "0.8em",
    lineHeight: 1.2,
  },
  crossmark: {
    opacity: 0,
    transition: "all 0.5s ease",
    width: "100%",
    height: "100%",
    position: "relative",
    border: "1px solid white",
  },
  infoProject: {
    zIndex: 10,
    position: "absolute",
    left: "1.5rem",
    bottom: "3rem",
  },
  blurBg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    backgroundColor: "black",
    filter: "blur(10px)",
    opacity: 1,
    zIndex: 0,
  },
  caption: {
    pointerEvents: "none",

    fontSize: "0.5em",
    lineHeight: 1.2,
    fontFamily: "Helvetica Neue",
    textTransform: "uppercase",
    color: "#fff",
  },
}));

export default Css;

import React from "react";
import { Frame } from "framer";
import Css from "./Css";
export default function ImageTitle({
  isHover,
  style,
  title,
  topHover,
  leftHover,
  top,
  left,
}) {
  const classes = Css();
  return (
    <Frame
      height="100%"
      width="100%"
      style={{
        fontFamily: "Helvetica Neue",
        fontWeight: 600,
        position: "relative",
        color: "#fff",
        margin: "0.5rem 0.25rem",
        textTransform: "uppercase",
        pointerEvents: "none",
        justifyContent: "start",
        fontSize: "0.8em",
        background: "none",
        textDecoration: "none !important",
      }}
      className={classes.ImageTitle}
    >
      {title}
    </Frame>
  );
}

import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";

import { motion } from "framer-motion";

// import { CursorContext } from "../../Cursor";

import ImageTitle from "../projects/ImageTitle";

import Gallery from "../Gallery/index.js";
import GalleryItem from "../GalleryItem/index.js";
import Image from "../Image/index.js";
import Item from "../Item.js";
import Css from "../projects/Css.js";
import { CursorContext } from "../Cursor/index.js";
import request from "graphql-request";
import CrossCanvas from "../CrossCanvas/index.js";

export function ProjectItem({
  id,
  top,
  project,
  description,
  left,
  backgroundAsset,
  featuredAsset,
  title,
}) {
  const [isHover, setIsHover] = useState(false);

  const classes = Css();

  const handleMouseEnter = (asset) => {
    document.body.className = "active-hover-project";
  };
  const handleMouseLeave = () => {
    document.body.className = "";
    document.body.style.backgroundImage = `none`;
  };
  return (
    <>
      <Item
        project={project}
        id={id}
        top={top}
        left={left}
        width={20}
        scrollSpeed={40}
        isHover={isHover}
        Thumbnail={featuredAsset}
      >
        <motion.div
          layoutId={"project-container-" + id}
          onPointerOver={() =>
            setIsHover(true) && handleMouseEnter(backgroundAsset.url)
          }
          onMouseEnter={() => handleMouseEnter(backgroundAsset?.url)}
          onMouseLeave={() => handleMouseLeave()}
          onPointerOut={() => setIsHover(false)}
          onPointerUp={() => setIsHover(false)}
        >
          <div className={"item__asset " + classes.assetrelative}>
            {featuredAsset.mimeType === "video/mp4" ? (
              <ReactPlayer
                url={featuredAsset.url}
                width={"100%"}
                height={"100%"}
                autoPlay={true}
                className={classes.video}
                muted={true}
                playsinline={true}
                loop
                playing
              />
            ) : (
              <Image src={featuredAsset.url} layoutId={"project-image-" + id} />
            )}
            {project.title !== "" && (
              <div className={classes.crossmark_block}>
                <div className={classes.crossmark}>
                  <CrossCanvas />
                </div>
              </div>
            )}
          </div>
          <ImageTitle isHover={isHover} title={title} />
        </motion.div>
      </Item>
    </>
  );
}

export default function ProjectInside({ id }) {
  const [projectCurrent, setProjectCurrent] = useState(null);
  const classes = Css();

  useEffect(() => {
    const fetchProjectCurrent = async () => {
      try {
        const { project } = await request(
          "https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clufxzm9c1u7v07tesjxj1rpb/master",
          `
            query ProjectById($id: ID!) {
              project(where: {id: $id}) {
                id
                title
                description {
                  html
                }
                featuredAsset {
                  url
                }
                backgroundAsset {
                  url
                }
                gallery {
                  asset {
                    fileName
                    url
                    mimeType
                  }
                  url
                }
              }
            }
          `,
          { id }
        );
        setProjectCurrent(project);
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };

    if (id) {
      fetchProjectCurrent();
    }
  }, [id]);

  if (!projectCurrent) {
    return <div>Loading...</div>;
  }

  return (
    <motion.div layoutId={"project-container-" + id}>
      {projectCurrent.backgroundAsset && (
        <div className={classes.blurBg}>
          <Image
            src={projectCurrent.backgroundAsset.url}
            layoutId={"project-image-" + id}
          />
        </div>
      )}
      <Gallery>
        {projectCurrent.gallery.map((item, index) => (
          <GalleryItem key={index}>
            {item.url ? (
              <ReactPlayer
                url={item.url}
                controls
                width="100%"
                height={"100%"}
                style={{ objectFit: "cover" }}
                playing
                muted
              />
            ) : item.asset ? (
              item.asset.mimeType === "video/mp4" ? (
                <ReactPlayer
                  url={item.asset.url}
                  width="auto"
                  height={"auto"}
                  style={{ objectFit: "cover" }}
                  playing
                  controls
                  muted
                />
              ) : (
                <Image
                  src={item.asset.url}
                  layoutId={"project-image-" + item.asset}
                />
              )
            ) : null}
          </GalleryItem>
        ))}
      </Gallery>
      <div className={classes.infoProject}>
        <div className={classes.title}>{projectCurrent.title}</div>

        <div
          className={classes.caption}
          dangerouslySetInnerHTML={{ __html: projectCurrent.description.html }}
        />
      </div>
    </motion.div>
  );
}

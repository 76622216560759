"use client";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { CursorContext } from "./Cursor";
import Css from "./projects/Css";
import CamCursor from "./Cursor/CamCursor";
import CanvasItem from "./CanvasItem";

export default function Item({
  id,
  top,
  left,
  width,
  fontSize,
  scrollSpeed,
  children,
  project,
  rotateX,
  rotateY,
  rotateZ,
  isHover,
}) {
  const classes = Css();
  const [hoveredProjectId, setHoveredProjectId] = useState(null);
  const setCursor = useContext(CursorContext);

  const handleMouseEnter = () => {
    if (setHoveredProjectId) {
      setHoveredProjectId(id);
    }
  };

  const handleMouseLeave = () => {
    if (setHoveredProjectId) {
      setHoveredProjectId(null);
    }
  };
  return (
    <CanvasItem
      top={top}
      left={left}
      width={width}
      fontSize={fontSize}
      scrollSpeed={scrollSpeed}
      className={classes.item}
      rotateX={rotateX}
      rotateY={rotateY}
      project={project}
      rotateZ={rotateZ}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isHover={isHover}
      hoveredProjectId={hoveredProjectId}
      setHoveredProjectId={setHoveredProjectId}
      id={id}
    >
      <Link
        to={project.description === null ? `` : `/home/${id}`}
        key={`item-${id}`}
        onPointerOut={() => setCursor(CamCursor("initial"))}
      >
        <div
          className={`${classes.cover} ${classes.coverScale} ${
            project.title === "" ? "hide-hover" : ""
          }`}
        >
          {children}
        </div>
      </Link>
    </CanvasItem>
  );
}

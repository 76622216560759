import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Grid } from "@material-ui/core";
import about from "./assets/about.mp4";
import about__bg from "./assets/about__bg.png";
import Player from "../../components/Player";
import { motion } from "framer-motion";
import Image from "../../components/Image";
import request from "graphql-request";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 1000px)": {
      paddingTop: theme.spacing(2.1),
    },
    "@media (max-width: 630px) and (pointer: coarse)": {
      marginTop: theme.spacing(2.5),
    },
  },
  typography: {
    fontFamily: "'Archivo Black', sans-serif",
    textTransform: "uppercase",
    fontSize: "3.222vw",
    "@media (max-width: 900px)": {
      fontSize: "3.8vw",
    },
    "@media (min-width: 1700px)": {
      fontSize: "2vw",
    },
    "@media (max-width: 700px) and (pointer: coarse)": {
      fontSize: "5.8vw",
    },
    "@media (max-width: 1000px)and (orientation: landscape) and (pointer: coarse)":
      {
        fontSize: "3vw",
      },
    "@media (max-width: 700px)and (orientation: landscape) and (pointer: coarse)":
      {
        fontSize: "3.5vw",
      },
    lineHeight: 1,
    color: theme.palette.text.primary,
    "& .MuiLink-root": {
      textDecoration: "none",
      color: "#fff",
    },
  },
  annexText: {
    fontFamily: "'Space Mono', monospace",
    textTransform: "initial",
    fontSize: "0.37em",
    lineHeight: 1,
    color: theme.palette.text.primary,
    verticalAlign: "top",
    marginLeft: "4px",
    "@media (max-width: 1100px)": {
      fontSize: "0.47em",
    },
  },
  email: {
    marginTop: "5%",
    display: "inline-block",
    color: "white",
    "&:hover:after": {
      transform: "scale(0)",
    },
  },
  video: {
    maxWidth: "100%",
    width: "100%",
    height: "100vh",
    opacity: "0.8",
    position: "fixed",
    objectFit: "cover",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    margin: "auto",
    zIndex: 0,
    background: "radial-gradient(circle, #363636, black, 0,7)",
  },

  description: {
    padding: "0 1.5rem",
  },
  item__about: {
    marginBottom: "2rem",
    "& span": {
      display: "block",
      position: "relative",
      fontWeight: "300",
      fontFamily: "Helvetica Neue",
      fontSize: "0.8rem",
      color: "#fff",
      lineHeight: 1,
    },
  },
  about__all: {
    position: "absolute",
    bottom: "3rem",
  },
  animationLink: {
    lineHeight: 1,
    position: "relative",
    fontWeight: "800",
    color: "#fff",
    fontSize: "2rem",
    textTransform: "uppercase",
    fontFamily: "Helvetica Neue",
  },
}));

export default function About() {
  const [Aboutpage, setAboutpage] = useState(null);

  useEffect(() => {
    const fetchAboutPage = async () => {
      const { aboutPages } = await request(
        "https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clufxzm9c1u7v07tesjxj1rpb/master",
        `
    {
      aboutPages {
        description {
          html
        }
        featuredAsset {
          url
        }
        metaTitle
        metaDescription
      }
    }`
      );
      console.log(aboutPages);
      setAboutpage(aboutPages[0]);
    };
    fetchAboutPage();
  }, []);
  const classes = useStyles();

  const variants = {
    initialVideo: {
      opacity: 0,
      x: "4%",
    },
    initialDescription: {
      opacity: 0,
      x: "-4%",
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeOut",
        duration: "0.3",
      },
    },
    out: {
      opacity: 0,
      y: "20%",
      transition: {
        duration: 0.3,
        ease: "easeOut",
      },
    },
  };

  return (
    <div key="page/about" className={classes.root}>
      {Aboutpage && (
        <Image
          src={Aboutpage.featuredAsset?.url}
          alt={"Portrait"}
          width="300"
          className={classes.video}
        />
      )}

      <Grid container alignItems="center">
        <Grid item md={6} className={classes.description}>
          <motion.div
            position={"relative"}
            size={"100%"}
            variants={variants}
            initial="initialDescription"
            animate="animate"
            exit="out"
            className={
              classes.about__all + " description__about " + classes.typography
            }
          >
            {Aboutpage && (
              <div
                dangerouslySetInnerHTML={{ __html: Aboutpage.description.html }}
              />
            )}
          </motion.div>
        </Grid>
      </Grid>
    </div>
  );
}

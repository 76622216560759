import React from "react";
import { makeStyles, styled } from "@material-ui/core";
import intro from "./assets/intro.mp4";
import CanvasItem from "../../CanvasItem";
import { Modal } from "@material-ui/core";
import Player from "../../Player";

import Slider from "react-slick";
// Import Swiper styles

const StyledSlider = styled(Slider)({
  "& .slick-track": {
    display: "flex",
    height: "100vh",
  },
  "& .slick-list": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
  },
  "& .slick-slide": {
    display: "flex",
    height: "100vh",
  },
  "& .slick-slide img": {
    width: "100vw!important",
    height: "100%!important",
    objectFit: "cover",
    filter: "blur(10px)",
  },
  "& .slick-arrow": {
    opacity: "0",
    visibility: "hidden",
    position: "absolute",
  },
});

const useStyles = makeStyles({
  fullscreen: {
    position: "relative",
    top: 0,
    left: 0,
    zIndex: 9999,
    backgroundColor: "black",
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    zIndex: 1000,
    transition: "height .8s, width .8s, top .8s, left .8s, opacity 1s",
    "&:hover": {
      opacity: 0,
      display: "none",
    },
    "&:not(:hover)": {
      opacity: 0,
      display: "none",
    },
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    backgroundColor: "black",
  },
  loadingBlock: {
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "calc(100% - 3rem)",
  },
  progressText: {
    color: "#fff",
    fontWeight: "bold",
    textDecoration: "none",
    textTransform: "uppercase",
    fontFamily: "Helvetica Neue",
    fontSize: "0.75rem",

    pointerEvents: "none",
  },
  gallery: {
    ".slick-list .slider-track": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      width: "calc(100% - 3rem)",
    },
    display: "flex!important",
    height: "100vh",
    width: "100%",
  },
  sliderContainer: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    left: "0",
    top: "0",
  },
  progress: {
    width: "100%",
    padding: "0 1.5rem",
    height: "2px",
    background: "linear-gradient(#fffff0 0 0) 0/0% no-repeat #0000;",
    animation: "$p1 10s infinite linear;",
  },
  "slick-track": {
    display: "flex",
    flexDirection: "row",

    width: "calc(100% - 3rem)",
  },
  "@keyframes p1": {
    "100%": {
      backgroundSize: "100%",
    },
  },
});

export default function VideoIntro(props) {
  const classes = useStyles();

  const scaler = Math.log1p(document.documentElement.clientWidth / 50) * 4.4;
  const width = document.documentElement.clientWidth / scaler;
  const height = document.documentElement.clientHeight / scaler;
  const left = -props.initialScroll.x / scaler;
  const top = -props.initialScroll.y / scaler;
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 50,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: false,
    waitForAnimate: true,
  };
  console.log('CONTNENT :" ' + props.content);
  return (
    <CanvasItem
      content={props.loadingAssets}
      onClick={props.onClick}
      top={props.fullscreen ? top : props.videoPositionY}
      left={props.fullscreen ? left : props.videoPositionX}
      width={props.fullscreen ? width : props.videoWidth}
      height={props.fullscreen ? height : props.videoHeight}
      scrollSpeed={10}
      className={props.fullscreen ? classes.fullscreen : classes.item}
    >
      {props.fullscreen ? (
        <Modal open={true}>
          <div className={classes.fullscreen}>
            <div className={classes.sliderContainer}>
              <StyledSlider>
                <Slider className={classes.gallery} {...settings}>
                  {props.content?.map((item, index) => (
                    <img src={item.url} key={index} />
                  ))}
                </Slider>
              </StyledSlider>
            </div>
            <div className={classes.loadingBlock}>
              <span className={classes.progressText}>LOADING</span>
              <div className={classes.progress}></div>
            </div>
          </div>
        </Modal>
      ) : (
        <video
          src={intro}
          type="video/mp4"
          autoPlay
          muted
          loop
          className={classes.video}
        />
      )}
    </CanvasItem>
  );
}
